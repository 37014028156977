<template>
  <div>
    <a-form
      :form="form"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol">
      <a-form-item
        hidden
        label="depId"
      >
        <a-input
          v-decorator="['depId',{ initialValue:'0' }]"
          name="depId"></a-input>
      </a-form-item>

      <a-form-item
        label="科室大类名称"
      >
        <a-input
          v-decorator="[
            'name',
            {rules: [{ required: true, message: '请输入科室大类名称' }]}
          ]"
          name="name"
          placeholder="请输入科室大类名称"/>
      </a-form-item>

      <a-form-item
        label="描述"
      >
        <a-textarea
          v-decorator="[
            'remarks',
            {rules: []}
          ]"
          name="remarks"
          placeholder="请输入描述"/>
      </a-form-item>
      <a-form-item
        label="排序"
      >
        <a-input
          v-decorator="[
            'listorder',
            {rules: [{ required: true, message: '请输入排序' }]}
          ]"
          name="path"
          placeholder="请输入排序"/>
      </a-form-item>
      <a-form-item
        :required="false"
        label="状态"
      >
        <a-radio-group v-decorator="['status', { initialValue: 1 }]" name="status">
          <a-radio :value="1">启用</a-radio>
          <a-radio :value="2">禁用</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
        form: this.$form.createForm(this)
      }
    }
  }
</script>
