var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"form":_vm.form,"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-form-item',{attrs:{"hidden":"","label":"depId"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['depId',{ initialValue:'0' }]),expression:"['depId',{ initialValue:'0' }]"}],attrs:{"name":"depId"}})],1),_c('a-form-item',{attrs:{"label":"科室大类名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {rules: [{ required: true, message: '请输入科室大类名称' }]}
        ]),expression:"[\n          'name',\n          {rules: [{ required: true, message: '请输入科室大类名称' }]}\n        ]"}],attrs:{"name":"name","placeholder":"请输入科室大类名称"}})],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'remarks',
          {rules: []}
        ]),expression:"[\n          'remarks',\n          {rules: []}\n        ]"}],attrs:{"name":"remarks","placeholder":"请输入描述"}})],1),_c('a-form-item',{attrs:{"label":"排序"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'listorder',
          {rules: [{ required: true, message: '请输入排序' }]}
        ]),expression:"[\n          'listorder',\n          {rules: [{ required: true, message: '请输入排序' }]}\n        ]"}],attrs:{"name":"path","placeholder":"请输入排序"}})],1),_c('a-form-item',{attrs:{"required":false,"label":"状态"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status', { initialValue: 1 }]),expression:"['status', { initialValue: 1 }]"}],attrs:{"name":"status"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("启用")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("禁用")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }